<template>
  <div>
    <a :href="`/images/${pic.full}`" v-for="pic in pics" :key="pic.full">
      <img alt="girl" :src="`/images/${pic.thumb.name}`">
    </a>
  </div>
</template>

<script setup>
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

const { data: pics } = await axios.get('/api/pics');
</script>
